import React from "react"
import styled from "styled-components"

import Layout from "../components/layout";
import SEO from "../components/seo";

import Section from '../components/section';
import Frame from '../components/frame';
import Button from "../components/button";
import ScrollAnalytics from '../components/analytics/scrollanalytics';


import {hasGdprConsentCookie, hasConsent, setConsent } from '../shared/cookies';

import { dimensions } from '../shared/styles';
import content from '../data/static-content.js';
import { colors } from '../shared/styles';

/* ---------------------------------------------- */
/* ---------------------------------------------- */

const MainSection = styled(Section)`
  padding-top : ${dimensions.headerHeight};
  margin-bottom : 15rem;
  position : relative;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : flex-start;

  font-size : 1.6rem;
`;

const MainFrame = styled(Frame)`
  max-width : 95%;
  margin : ${dimensions.headerHeight};

  @media screen and (max-width : 425px) {
    margin : ${dimensions.headerHeight} 4rem;
  }
`;

const ConsentParagraph = styled.p`
  text-align : center;
  margin-top : 5rem;
`;

const ButtonsContainer = styled.div`
  text-align : center;
  margin-bottom : 5rem;
`;

const ConsentButton = styled(Button)`
  margin : 5rem 5rem 0rem 5rem;
`;

const RejectButton = styled(ConsentButton)`
  & .button-content {
    color : ${colors.darkBlue};
    background-color : white;
    border : 1px solid ${colors.darkBlue};
  }
`;

const H1 = styled.h1`
  @media screen and (max-width : 425px) {
    font-size : 2.5rem;
  }
`;


const PrivacyPage = () => {
  const onReject = () => {
    setConsent(false);
    window.location.reload();
  };

  const onConsent = () => {
    setConsent(true);
    window.location.reload();
  }

  return (
    <Layout>
      <SEO title="Appunto" />
      <MainSection>
        <MainFrame
          tr={{animation: 'rotate'}}
          bl={{animation: 'in-out', color : "pink"}}
          contentStyle={{paddingBottom : '2rem'}}
        >
          <>
            <H1>{content.privacy.title}</H1>
            {
              content.privacy.content.map(
                (section, sectionIndex) => (
                  <React.Fragment key={sectionIndex}>
                    <h3>{section.title}</h3>
                    {
                      section.content.map(
                        (paragraph, i) => <p key={i}>{paragraph}</p>
                      )
                    }
                  </React.Fragment>
                )
              )
            }
            <h3>{content.privacy.consentTitle}</h3>
            <ScrollAnalytics action="scroll-privacy-buttons">
              <ConsentParagraph>
                {content.privacy.consentText}
                <strong>
                  {hasGdprConsentCookie() ? (hasConsent() ? content.privacy.consentGiven : content.privacy.consentRejected) : '---'}
                </strong>
              </ConsentParagraph>
            </ScrollAnalytics>
            <ButtonsContainer>
              <ConsentButton onClick={onConsent}>{content.privacy.giveConsent}</ConsentButton>
              <RejectButton onClick={onReject}>{content.privacy.rejectConsent}</RejectButton>
            </ButtonsContainer>
          </>
        </MainFrame>
      </MainSection>
    </Layout>
  );
}


export default PrivacyPage
