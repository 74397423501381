import React from "react"

const Button = ({children, onClick, className = '', style, ...props}) =>
  <div
    className={`button-container ${className}`}
    style={style}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex="0"
  >
    <div className='button-shadow'/>
    <div className='button-content'>
      {children}
    </div>
  </div>;

export default Button;
